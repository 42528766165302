<template>
  <div>
    <h1 class="mb-4 text-h5 text-md-h4 font-weight-bold">
      {{ product.name }}
    </h1>
    <div class="mb-4">
      <p
        class=""
        v-html="product.introduction"
      />
    </div>
    <product-attributes :product="product" />
    <div
      v-if="product.reviews && product.reviews.length > 0"
      class="d-flex mb-4"
    >
      <p class="grey--text text--grey-lighten-1 me-3 mb-0">
        {{ $t("Notations") }}:
      </p>
      <div class="d-flex align-center justify-center align-center mb-1">
        <v-rating
          :value="product.reviews_avg_rating"
          color="amber"
          dense
          half-increments
          readonly
          size="16"
        />

        <div class="grey--text text--darken-4 ml-1">
          ({{ product.reviews.length }})
        </div>
      </div>
    </div>
    <div class="mb-4">
      <div class="">
        <clock
          v-if="product.promo"
          :now="product.promo.now"
          :future="product.promo.dateend"
          :start="product.promo.datestart"
          :single="true"
        />
      </div>
      <product-price :product="product" />

      <p class="">
        <span v-if="product.quantity">{{ product.quantity }} {{ $t("Stock disponible") }}</span>
        <span v-else>{{ $t("En rupture de stock") }}</span>
      </p>
    </div>
    <div class="mb-6">
      <v-row
        no-gutters
        class="d-flex"
      >
        <v-col
          cols="auto"
          md="6"
          lg="3"
          xl="3"
        >
          <add-to-cart-button
            :product="product"
            :mini="false"
          />
        </v-col>
      </v-row>
    </div>
    <div class="d-flex mb-4">
      <!--          <p class="grey&#45;&#45;text text&#45;&#45;grey-lighten-1 me-3">
        {{ $t("Vendeur") }}:
      </p>-->
      <p class="font-600">
        <vendor-link
          :name-length="100"
          :show-country="true"
          :vendor="{...product.user,
                    path: `/profile/${product.user.id || ''}`,
          }"
        />
      </p>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'

  import AddToCartButton from '../../components/products/AddToCartButton'
  import VendorLink from '../../components/VendorLink'
  import ProductPrice from '../../components/products/ProductPrice'
  import ProductAttributes from '../../components/products/ProductAttributes'
  import { i18n } from '../../plugins/i18n'
  import Clock from '../../components/Clock.vue'

  export default {
    components: {
      Clock,
      ProductAttributes,
      AddToCartButton,
      VendorLink,
      ProductPrice,
    },
    props: {
      value: { type: [Object, Array], default: () => { return { images: [] } } },
    },
    data () {
      return {
        tab: null,
        loading: false,
        isQuantity: 0,
        product: {},
        attributes: [],
        productattributes: [],
        breadcrumbs: [
          {
            text: i18n.t('Accueil'),
            disabled: false,
            to: '/',
          },
        ],
      }
    },
    computed: {
      ...mapGetters(['getProducts']),
      ...mapState({
        user: 'user',
      }),
      pathParams () {
        return this.$route.params.slug
      },
    },
    watch: {
      value (val) {
        this.product = this.value
      },
    },
    created () {
      this.product = this.value || { images: [] }
    },
    destroyed () {

    },
    methods: {
      ...mapActions(['addCart', 'removeCart', 'fetchProduct']),
      ...mapMutations([
        'pushItem', // map `this.increment()` to `this.$store.commit('increment')`
        'removeItem', // map `this.increment()` to `this.$store.commit('increment')`

        // `mapMutations` also supports payloads:
        'setValue', // map `this.incrementBy(amount)` to `this.$store.commit('incrementBy', amount)`
      ]),

      addCartx (data) {
        this.addCart(data)
      },
      removeCartx (data) {
        this.removeCart(data)
      },

    },
  }
</script>
<style lang="scss" scoped>
    .product-slider {

        .product-slider-click {
            display: flex;
            justify-content: center;
            ::v-deep .slick-slide {
               width: 80px !important;
               margin-right: 16px;
               border: 1px solid rgb(218, 225, 231);
               border-radius: 5px;
            }
            ::v-deep .slick-current {
                border: 1px solid #D23F57;
                border-radius: 5px;
            }
        }
    }
</style>
