<template>
  <v-container class="my-6">
    <v-row>
      <v-col
        v-if="showWarning"
        cols="12"
      >
        <v-alert
          prominent
          type="warning"
        >
          <v-row align="center">
            <v-col class="grow">
              {{ $t("Attention, Vous devez complèter votre profile avant de commencer à vendre des produits. Merci") }}
            </v-col>
            <v-col class="shrink">
              <v-btn @click="showWarning = false">
                {{ $t("Close") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
      <v-col cols="12">
        <h2>{{ $t("Formulaire de Produit") }}</h2>
        <v-tabs
          v-model="tab"
          grow
          show-arrows
        >
          <v-tabs-slider />
          <v-tab
            key="tab-info"
            href="#tab-info"
          >
            {{ $t("Details") }}
          </v-tab>
          <v-tab
            key="tab-images"
            href="#tab-images"
            :disabled="!product.id"
          >
            {{ $t("Images") }}
          </v-tab>
          <v-tab
            key="tab-categories"
            href="#tab-categories"
            :disabled="!product.id"
          >
            {{ $t("Categories") }}
          </v-tab>
          <v-tab
            key="tab-publish"
            href="#tab-publish"
            :disabled="!product.id"
          >
            {{ $t("Publish") }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            key="tab-info"
            value="tab-info"
          >
            <product-details-form
              v-model="product"
              @saved="productSaved"
            >
              <template #next-button>
                <v-btn
                  v-if="product.id"
                  color="primary"
                  @click="goToTab('tab-images')"
                >
                  {{ $t("Suivant") }}
                </v-btn>
              </template>
            </product-details-form>
          </v-tab-item>
          <v-tab-item
            key="tab-images"
            :disabled="!product.id"
            value="tab-images"
          >
            <product-images-form
              v-if="product.id"
              v-model="product"
              @saved="imagesSaved"
            >
              <template #actions>
                <v-card-actions>
                  <v-btn
                    color="primary"
                    @click="goToTab('tab-info')"
                  >
                    {{ $t("Precedent") }}
                  </v-btn>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    @click="goToTab('tab-categories')"
                  >
                    {{ $t("Suivant") }}
                  </v-btn>
                </v-card-actions>
              </template>
            </product-images-form>
          </v-tab-item>
          <v-tab-item
            key="tab-categories"
            :disabled="!product.id"
            value="tab-categories"
          >
            <v-card flat>
              <v-card-text>
                <product-form-category
                  v-if="product.id"
                  :product="product"
                  @saved="categoriesSaved"
                />
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="primary"
                  @click="goToTab('tab-images')"
                >
                  {{ $t("Precedent") }}
                </v-btn>
                <v-spacer />
                <v-btn
                  color="primary"
                  @click="goToTab('tab-publish')"
                >
                  {{ $t("Suivant") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-tab-item>
          <v-tab-item
            key="tab-publish"
            :disabled="!product.id"
            value="tab-publish"
          >
            <product-publish-form

              v-if="product.id && tab === 'tab-publish'"
              v-model="product"
            >
              <template #back-button>
                <v-btn
                  color="primary"
                  @click="goToTab('tab-categories')"
                >
                  {{ $t("Precedent") }}
                </v-btn>
              </template>
            </product-publish-form>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-snackbar v-model="isSnackbarBottomVisible">
      {{ snackBarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="snackBarColor"
          text
          v-bind="attrs"
          @click="isSnackbarBottomVisible = false"
        >
          {{ $t("Fermer") }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="loading"
      persistent
      width="70"
      class="overflow-hidden transparent"
    >
      <v-card
        width="70"
        height="70"
        class="overflow-hidden"
      >
        <v-row
          no-gutters
          justify="center"
          align="center"
          class="fill-height align-self-center"
        >
          <v-col
            cols="12"
            class="text-center"
          >
            <v-progress-circular
              indeterminate
              color="primary"
              class="mx-auto"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { FormWizard, TabContent } from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import { mapGetters, mapActions, mapState } from 'vuex'
  import AddToCartButton from '../../components/products/ProductPrice'
  import VueUploadMultipleImage from 'vue-upload-multiple-image'
  import { i18n } from '../../plugins/i18n'
  import ProductFormCategory from '../../components/ProductFormCategory.vue'
  import CardCart from '../../components/cartCard/CardCart.vue'
  import ProductDetailsForm from './ProductDetailsForm.vue'
  import ProductImagesForm from './ProductImagesForm.vue'
  import ProductPublishForm from './ProductPublishForm.vue'

  export default {
    components: {
      ProductPublishForm,
      ProductImagesForm,
      ProductDetailsForm,
      CardCart,
      ProductFormCategory,
      FormWizard,
      TabContent,
      VueUploadMultipleImage,
    },
    data () {
      return {
        step: 0,
        tab: null,
        showWarning: false,
        productForm: null,
        validAddressForm: false,
        loading: false,
        loading_points: false,
        loading_cities: false,
        create_new: false,
        security_tax: 0,
        commission_minimal: 0,
        formData: null,
        fileList: [],
        donate: 0,
        details: {},
        form: {},
        usercountry: 0,
        tvas: [],
        transporters: [],
        system_transporters: [],
        shipment_methods: [],
        payment_methods: [],
        relay_points: [],
        packages: [],
        images: [],
        package: {},
        errors: {
          firstname: false,
          lastname: false,
          email: false,
          phone: false,
          country: false,
          address: false,
          zip: false,
          city: false,
        },

        errorMessages: { name: 'Est requis' },
        termsRules: [v => !!v || i18n.t('Accepter les conditions générales')],
        firstNameRules: [v => !!v || i18n.t('Le prénom est requis')],
        lastNameRules: [v => !!v || i18n.t('Le nom de famille est requis')],
        emailRules: [v => !!v || i18n.t('L\'e-mail est obligatoire')],
        passwordRules: [v => !!v || i18n.t('Le mot de passe est requis')],
        telRules: [v => !!v || i18n.t('Le numéro de téléphone est requis.')],
        addressRules: [v => !!v || i18n.t('L\'adresse est requise.')],
        introRules: [v => !!v || i18n.t('L\'introduction est requise.')],
        nameRules: [v => !!v || i18n.t('Le Nom du produit est requise.')],
        publicRules: [v => !!v || i18n.t('Le Prix public est requise.')],
        items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
        radios: null,
        radiosTwo: null,
        cart: [
        ],
        product: {
          images: [],
        },
        order: {
          user: {
            country_code: null,
          },
          country_code: null,
          city: null,
          post_code: null,
        },
        addresses: [],
        deliveryaddress: [],
        snackBarMessage: '',
        snackBarColor: null,
        isSnackbarBottomVisible: false,

      }
    },
    computed: {
      ...mapGetters(['getCartProducts', 'unreadNotifications']),
      ...mapState({
        user: 'user',
        salutations: 'salutations',
        countries: 'countries',
        displayNotification: 'display_notification',
      }),

      cartTotal () {
        let total = 0
        this.getCartProducts.forEach(product => {
          total += product.amount * product.qty
        })
        return total
      },
      backButtonLabel () {
        return this.$t('Précédent')
      },
      nextButtonLabel () {
        if (this.step === 0) {
          return this.$t('Suivant')
        }
        if (this.step === 1) {
          return this.$t('Procéder au Categories')
        }
        if (this.step === 2) {
          return this.$t('Procéder a la publication')
        }
        return this.$t('Suivant' + this.step)
      },
      finishButtonLabel () {
        if (this.product && this.product.active) {
          return this.$t('Deactiver')
        }
        return this.$t('Publier')
      },
      productImages () {
        return this.product.images.map((e) => { return { ...e, path: e.image } })
      },
    },
    mounted () {
      if (this.$route.params.slug) {
        this.getProduct()
      }
    },
    created () {
      this.getTransporters()
    },
    methods: {
      ...mapActions(['addCart', 'removeCart']),
      productSaved (item) {
        this.goToTab('tab-images')
        window.scrollTo(0, 0)
        if (!this.$route.params.slug) {
          this.$router.push({ name: 'EditProduct', params: { slug: item.slug }, query: { tab: 'tab-images' } })
        }
      },
      imagesSaved (item) {

      },
      categoriesSaved (item) {
        this.goToTab('tab-publish')
      },
      goToTab (t) {
        window.scrollTo(0, 0)
        console.log(t)
        this.tab = t
        this.save()
      },
      getTransporters () {
        axios.get('/transporters', { params: { with_system: true } }).then((response) => {
          if (response.data && response.data.data) {
            this.transporters = response.data.data
          }
        })
      },
      getProduct () {
        this.loading = true
        axios
          .get(`/products/${this.$route.params.slug}/edit`)
          .then(response => {
            if (response.data && response.data.product) {
              var ele = this.processProduct(response.data.product)
              var related_products = ele.related_products || []
              var transp = ele.transporters || []

              this.product = {
                ...ele,
                transporters_ids: transp.map((e) => e.id),
                qty: (this.$store.state.cart.products.find((el) => el.id === ele.id) || { qty: 0 }).qty || 0,
                related_products: related_products.map(el => {
                  el.qty = (this.$store.state.cart.products.find((e) => e.id === el.id) || { qty: 0 }).qty || 0
                  return this.processProduct(el)
                }),
              }
              this.images = this.productImages
            }
            if (response.data.tvas) {
              this.tvas = response.data.tvas
            }
          })
          .catch(error => console.log(error)).then(() => {
            this.loading = false
          })
      },
      validateCart () {
        return this.getCartProducts.length > 0
      },
      validateForm () {
        const isFormValid = this.$refs.productForm.validate()
        if (!isFormValid) {
          this.toast(this.$t('Veuillez remplir tous les champs obligatoires.'), 'red')
          return false
        }

        this.save()

        /* axios.post('/products', this.form).then((res) => {
          this.step = 1
          return true
        }).catch((err) => {
          return false
        }) */
        return true
      },
      publish () {
        /* const isFormValid = this.$refs.productForm.validate()
        if (!isFormValid) {
          this.toast(this.$t('Veuillez remplir tous les champs obligatoires.'), 'red')
          return false
        } */

        if (this.product.active) {
          this.product.active = false
        } else {
          this.product.active = true
        }
        this.save()

        /* axios.post('/products', this.form).then((res) => {
          this.step = 1
          return true
        }).catch((err) => {
          return false
        }) */
        return true
      },
      save () {
        /* const isFormValid = this.$refs.productForm.validate()

        if (!isFormValid) return
        this.loading = true */

        axios({
          method: 'post',
          url: '/products',
          data: { ...this.product },
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        }).then((response) => {
          if (response.data && response.data.product) {
            var ele = this.processProduct(response.data.product)
            var related_products = ele.related_products || []
            var transp = response.data.product.transporters || []

            this.product = {
              ...ele,
              transporters_ids: transp.map((e) => e.id),
              qty: (this.$store.state.cart.products.find((el) => el.id === ele.id) || { qty: 0 }).qty || 0,
              related_products: related_products.map(el => {
                el.qty = (this.$store.state.cart.products.find((e) => e.id === el.id) || { qty: 0 }).qty || 0
                return this.processProduct(el)
              }),
            }
            this.images = this.productImages
          }
          if (response.data.tvas) {
            this.tvas = response.data.tvas
          }
          this.step = 1
        }).catch((err) => {
          this.loading = false
          return false
        }).then(() => {
          this.loading = false
        })
        return true
      },
      toast (message, color) {
        this.isSnackbarBottomVisible = true
        this.snackBarColor = color
        this.snackBarMessage = message
      },
      removeCartx (item) {
        this.removeCart(item)
      },
      addCartx (item) {
        this.addCart(item)
      },

      addressChanged (event) {
        var item = this.getItem(this.addresses, 'id', event.target.value)

        this.order.address = item
      },
      countryChanged (event) {
        // console.log(event.target.value);
        var code = this.order.country_code
        this.order.user.country_code = this.order.country_code
        this.getCities(code)
      // this.relaypoints(0);
      },
      uploadImageSuccess (formData, index, fileList) {
        // this.formData = formData
        // this.fileList = fileList
        this.submitImage(formData)
        // const data = new FormData()
        /* for (const file of fileList) {
            this.product.photos.push(file)
           // data.append(this.inputname, file, file.name)
          } */

        // return data
        // console.log('data', formData, index, fileList)
        // Upload image api
        // axios.post('http://your-url-upload', formData).then(response => {
        //   console.log(response)
        // })
      },
      beforeRemove (index, done, fileList) {
        console.log('index', index, fileList)
        var r = confirm('Êtes-vous sûr de supprimer l\'image ?')
        if (r == true) {
          if (this.product && this.product.id && this.product.media && this.product.media.length > 0) {
            this.product.images.splice(index, 1)
            this.product.media.splice(index, 1)
            // eslint-disable-next-line no-undef
            axios.post(`/product/order_photos/${this.product.id}`, { images: this.product.media.map((e) => e.id) }).then((res) => {})
          }
          done()
        } else {
        }
      },
      editImage (formData, index, fileList) {
        // this.formData = formData
        // this.fileList = fileList

        this.submitImage(formData)
        console.log('edit data', formData, index, fileList)
      },
      submitImage (form, saved = false) {
        // if (!this.product.id && this.form) return
        // const url = "admin/customer/photo/" + this.customer.id;
        // upload data to the server
        //    this.currentStatus = STATUS_SAVING;
        // this.$emit('input', null);

        // this.formData = null

        // this.loading = true
        axios.post(`/product/store_photo/${this.product.id}`, form)
          .then(response => {
            // this.product.images.push(response.data)
            // this.product.media = this.product.media || []
            // this.product.media.push(response.data)
            /* if (saved) {
              this.toast(this.$t('Produit enregistrer avec success'), 'success')
            } */
          })
          .catch(error => {

          }).then(() => {
            this.loading = false
          })
      },
      getCities (code) {
        var country = this.getItem(this.countries, 'code', code)
        var _this = this
        // this.order.address.city_id = null;

        if (!country.cities || country.cities.length === 0) {
          this.loading_cities = true
          axios.get('/countries/' + code).then((response) => {
            _this.loading_cities = false
            country.cities = response.data
            Vue.set(_this.countries, _this.getIndex(_this.countries, 'code', code), country)
          })
        }
        ;
        return country.cities || []
      },
      cityChanged (event) {
      // this.relaypoints(0);

      },
      zipChanged (event) {
      // this.relaypoints(0);

      },
      transporterChanged (event, pkg) {
        // this.relaypoints(0);

        console.log(pkg)
        this.getRelayPoints(pkg)
      },
      relaypoints (local = 0) {
        var transporter_id = this.order.transporter_id
        var country_id = this.order.user.country_code
        var city_id = this.order.user.city

        if (!country_id) {
          return []
        }
        if (!transporter_id) {
          return []
        }
        if (!this.order.user.zip || this.order.user.zip.length < 3) {
          return []
        }
        this.relay_points = []
        var that = this
        that.loading_points = true
        axios.get('/relay_points/' + transporter_id + '?&local=' + local,
                  { params: { country_id: country_id, city_id: city_id, zip: this.order.user.zip } })
          .then((response) => {
            this.relay_points = response.data
            that.loading_points = false
          }).catch(error => {
            that.loading_points = false
            alert(this.$t('Aucun point relais trouvé ici.'))
          })
      },
      getRelayPoints (pkg) {
        console.log(pkg.transporter_id)
        var transporter_id = pkg.transporter_id
        var country_id = this.order.user.country_code
        var city_id = this.order.user.city

        if (!country_id) {
          return []
        }
        if (!transporter_id) {
          return []
        }
        if (!this.order.user.zip || this.order.user.zip.length < 3) {
          return []
        }
        this.relay_points = []
        var that = this
        that.loading_points = true
        axios.get('/transporters/relay_points/' + transporter_id + '?&local=0',
                  { params: { country_id: country_id, city_id: city_id, zip: this.order.user.zip } })
          .then((response) => {
            pkg.points = response.data
            that.loading_points = false
          }).catch(error => {
            that.loading_points = false
            alert(this.$t('Aucun point relais trouvé ici.'))
          })
      },
      transporters (pk) {
        // console.log(pk.seller)

        var trans = [] // pk.seller.transporters || [];
        if (pk.seller.country_code === 'FR') {
          for (var i = 0; i < this.system_transporters.length; i++) {
            if (this.checkTariff(pk, this.system_transporters[i])) {
              trans.push(this.system_transporters[i])
            }
          }
        }
        /**/
        for (let i = 0; i < pk.seller.transporters.length; i++) {
          if (this.checkTariff(pk, pk.seller.transporters[i])) {
            trans.push(pk.seller.transporters[i])
          }
        }

        return trans
      },
      isSystemTransporter (transporter_id) {
        for (var i = 0; i < this.system_transporters.length; i++) {
          if (parseInt(transporter_id) === parseInt(this.system_transporters[i].id)) {
            return true
          }
        }
        return false
      },

      close () {
        $('#paymentbox').modal('hide')
        $('#paymentbox').find('.modal-body').html('')
      },
      pay (pkg) {
        $('#paymentbox').find('.modal-body').html('')
        $('#paymentbox').modal('show')
        this.loading = true
        axios.post('/card-registration', {
          currency: this.order.currency || 'EUR',
          cost: pkg.total,
          payment_type: '<?php echo e(\App\MangoPayApi::package); ?>',
          cardtype: this.order.card_type || '',
          returnurl: 'packages.pay',
          entity_id: pkg.id,
        }).then((response) => {
          this.loading = false
          $('#paymentbox').find('.modal-body').html(response.data)
        }).catch((error) => {
          this.loading = false
        })
      },
      payAll () {
        $('#paymentbox').find('.modal-body').html('')
        $('#paymentbox').modal('show')
        this.loading = true
        axios.post('/card-registration', {
          currency: this.order.currency || 'EUR',
          cost: this.order.total,
          cardtype: this.order.card_type || '',
          payment_type: '<?php echo e(\App\MangoPayApi::order); ?>',
          returnurl: 'cart.pay',
          entity_id: this.order.id,
        }).then((response) => {
          this.loading = false
          $('#paymentbox').find('.modal-body').html(response.data)
        }).catch((error) => {
          console.log(error)
          this.loading = false
          $('#paymentbox').find('.modal-body').html(error)
        })
      },
      getTax () {
        // if (this.order.donate) {
        //     t += this.donate;
        // }
        var security_fee = parseFloat(((this.security_tax / 100) * this.subTotal()).toFixed(2))
        if (security_fee < this.commission_minimal) { return this.commission_minimal }
        return security_fee// .toFixed(2);
      },
      getTotal () {
        var t = this.getTax() + parseFloat(this.subTotal())

        // if (this.order.donate) {
        //     t += this.donate;
        // }

        return t.toFixed(2)
      },
      subTotal () {
        var t = 0
        for (var i = 0; i < this.packages.length; i++) {
          t += parseFloat(this.getPackageTotal(this.packages[i]))
        }
        return t // t.toFixed(2);
      },
      checkTariff (pk, t) {
        var tas = t.tariffs || []

        for (var i = 0; i < tas.length; i++) {
          if ((tas[i].min_weight < pk.total_weight + 1) && (tas[i].max_weight > pk.total_weight - 1) && (tas[i].country_code === this.order.user.country_code)) {
            // return tas[i];
            return true
          }
        }
        // not found so we take the first price where min weight is less than the total weight
        /* for(var i=0;i<tas.length;i++){
          if(tas[i].min_weight < pk.total_weight+1  && tas[i].country_code == this.order.user.country_code){
              return tas[i];
          }
      } */
        return false
      },
      getTariffValue (pk) {
        console.log(pk)
        var tariff = this.getTariff(pk)
        if (this.isSystemTransporter(pk.transporter_id)) {
          console.log('transporter system')
          return parseFloat(tariff.weight_price_ttc)
        }
        if (parseInt(pk.exportation) === 0) {
          if (tariff.weight_price_ttc) { return parseFloat(tariff.weight_price_ttc) }

          return parseFloat(tariff.weight_price)
        }

        return parseFloat(tariff.weight_price)
      },
      getTariff (pk) {
        if (!pk.transporter_id) {
          return { weight_price: 0, not_exist: true }
        }

        // using system transporters instead of seller transporter.
        var tas = this.getItem(pk.seller.transporters || [], 'id', pk.transporter_id).tariffs || []
        var tasys = this.getItem(this.system_transporters || [], 'id', pk.transporter_id).tariffs || []

        tasys.forEach((item) => {
          tas.push(item)
        })

        for (var i = 0; i < tas.length; i++) {
          // pk.total_weight = 2

          // console.log("country code",this.order.user.country_code);
          console.log('tariff_country_code', tas[i].min_weight, pk.total_weight, tas[i].max_weight, tas[i].country_code)
          if ((tas[i].min_weight < pk.total_weight) && (tas[i].max_weight >= pk.total_weight) && (tas[i].country_code === this.order.user.country_code)) {
            console.log('selected ', tas[i])
            return tas[i]
          }
        }

        // not found so we take the first price where min weight is less than the total weight
        // for (var i = 0; i < tas.length; i++) {
        //     if ((tas[i].min_weight < pk.total_weight + 1) && (tas[i].country_code === this.order.user.country_code)) {
        //         return tas[i];
        //     }
        // }

        return { weight_price: 0, weight_price_ttc: 0, not_exist: true }
      },
      getTva (pk) {
        return 19.0
      },
      getPackageTotal (pk) {
        var to = 0
        for (var i = 0; i < pk.items.length; i++) {
          to += parseFloat(pk.items[i].quantity * pk.items[i].price)
        }

        var tariffvalue = this.getTariffValue(pk)

        // if(parseInt(pk.exportation) === 0){
        //     if(tariff.weight_price_ttc)
        //         return (to + parseFloat(tariff.weight_price_ttc )).toFixed(2);
        //
        //     return (to + parseFloat(tariff.weight_price )).toFixed(2);
        // }
        //
        // return (to + parseFloat(tariff.weight_price)).toFixed(2);
        return (to + tariffvalue).toFixed(2)
      /* if (!pk.country_id || !pk.transporter_id) {
           return {weight_price: 0, not_exist: true};
       } */
      // return this.getItem(this.getItem(this.transporters(pk), 'id', pk.transporter_id).tariffs || [], "country_id", pk.country_id)
      },
      getItem (items, field, value) {
        for (var i = 0; i < items.length; i++) {
          if (items[i][field] == value) {
            return items[i]
          }
        }
        return false
      },

      getIndex (items, field, value) {
        for (var i = 0; i < items.length; i++) {
          if (items[i][field] == value) {
            return i
          }
        }
        return -1
      },
    },
  }
</script>
